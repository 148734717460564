import React from 'react';
import Translation from 'components/data/Translation';
import { InputAttribute } from 'components/template-designer/components/dynamic-layers/types/attribute.type';
import { SelectSettings } from 'components/template-designer/types/dynamicLayer.type';
import { MultiSelectFormDynamic } from '../forms/multi-select-form-dynamic';

const frameFitting: InputAttribute<SelectSettings> = {
    label: Translation.get('dynamicLayers.labels.frameFitting', 'template-designer'),
    description: Translation.get('dynamicLayers.descriptions.frameFitting', 'template-designer'),
    attribute: 'frameFitting',
    settings: {
        select: {
            type: 'select',
            options: [
                {
                    key: 'CENTER_CONTENT',
                    value: Translation.get('sidebarRight.inputs.frameFittingOptions.centerContent', 'template-designer')
                },
                {
                    key: 'CONTENT_AWARE_FIT',
                    value: Translation.get('sidebarRight.inputs.frameFittingOptions.contentAwareFit', 'template-designer')
                },
                {
                    key: 'CONTENT_TO_FRAME',
                    value: Translation.get('sidebarRight.inputs.frameFittingOptions.contentToFrame', 'template-designer')
                },
                {
                    key: 'FILL_PROPORTIONALLY',
                    value: Translation.get('sidebarRight.inputs.frameFittingOptions.fillProportionally', 'template-designer')
                },
                {
                    key: 'FRAME_TO_CONTENT',
                    value: Translation.get('sidebarRight.inputs.frameFittingOptions.frameToContent', 'template-designer')
                },
                {
                    key: 'PROPORTIONALLY',
                    value: Translation.get('sidebarRight.inputs.frameFittingOptions.proportionally', 'template-designer')
                }
            ]
        }
    },
    defaultSetting: 'select',
    editForm: () => (
        <>
            <MultiSelectFormDynamic inputType="text" attribute={frameFitting.attribute} disabledIndex={0} />
        </>
    )
};

export { frameFitting };
